import React, {useState} from 'react';
import {
  ChakraProvider,
  Box,
  theme,
  SimpleGrid,
  Button
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';

const SUBSCRIPTIONS = [{name: 'mingguan', price: 9000}, {name: 'bulanan', price: 16000}, {name: 'tahunan', price: 87000}];

function App() {
  const [loading, setIsLoading] = useState(false);
  return (
    <ChakraProvider theme={theme}>
      <ColorModeSwitcher justifySelf="flex-end" />
      <SimpleGrid
        columns={3}
        display='flex'
        alignItems='flex'
        justifyContent='space-between'
        margin='20px'
      >
        {SUBSCRIPTIONS.map(({price, name}) => <Product
          isLoading={loading}
          updateLoading={(val) => setIsLoading(val)}
          key={name}
          price={price}
          name={name}
        />)}
      </SimpleGrid>
    </ChakraProvider>
  );
}

const Product = ({name, price, isLoading, updateLoading}) => {
  return <Box
    borderWidth='1px'
    borderRadius='lg'
    justifyContent='center'
    alignItems='center'
    display='flex'
    flexDir='column'
    w='30%'
    padding='15px'
  >
    <Box
      mt='1'
      fontWeight='semibold'
      as='h4'
      lineHeight='tight'
      isTruncated
    >
      {name}
    </Box>
    <Box
      mt='1'
      fontWeight='semibold'
      as='h4'
      lineHeight='tight'
      isTruncated
    >
      {price} IDR
    </Box>
    <Button
      isLoading={isLoading}
      loadingText='Memuat...'
      colorScheme='green'
      size='sm'
      onClick={async () => {
        updateLoading(true);
        const res = await fetch('https://xendit.stivisto.com', {
          method: 'POST',
          headers: {'content-type': 'application/json'},
          body: JSON.stringify({price})
        })
            .finally(() => updateLoading(false));
        const jsonRes = await res.json();
        if (jsonRes.url) {
          window.location.replace(jsonRes.url);
        }
      }}
    >
      Beli
    </Button>
  </Box>
}

export default App;
